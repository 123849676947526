import * as React from 'react'

import Layout, { Block, LeadingMotto } from '../components/Layout'
import GreenStar from '../images/green_star.svg'
import Button from '../components/Button'
// import Stats from '../components/Stats'
import { Link } from 'gatsby'
// import MIPTInfo from '../components/MIPTInfo'
import Seo from '../components/seo'

const IndexPage = () => (
	<Layout>
		<Seo title='Phystech Ventures' />
		<Block
			color='bgGray'
			className='pt-28 md:pt-48 pb-10 2xl:pt-72 2xl:pb-20'
			nestedClassName='p-7'
		>
			<LeadingMotto
				icon={<GreenStar />}
				text={
					<div className='w-auto lg:w-3/5'>
						Early supporters of global disruption in deeptech
					</div>
				}
			/>
			<div className='pb-6 2xl:pb-10 max-w-7xl mx-auto'>
				<div className='mt-10 people-md pt-0 leading-header w-full xl:w-3/6'>
					<p className='text-grey'>
						Welcome to Phystech Ventures, a leading deeptech venture capital fund dedicated to fueling the next generation of scientific breakthroughs. We specialize in investing in Deeptech startups that leverage fundamental scientific advancements to create disruptive solutions and transform industries.
					</p>
				</div>
			</div>
			<Link to='/firm/'>
				<Button className='mt-6'>Learn more</Button>
			</Link>
		</Block>
		{/*
		<Block
			color='yellowGreen'
			className='block md:pt-1 md:pb-16'
			nestedClassName='p-0 md:p-7'
		>
			<Stats />
		</Block>
        */}
		{/*<Block color='bgGray' className='block pt-1 pb-16' nestedClassName='p-7'>
			<MIPTInfo />
		</Block>*/}
	</Layout>
)

export default IndexPage
