import * as React from 'react'

interface IProps {
	className?: string
}

const Button: React.FC<IProps> = ({children, className}) => (
	<div
		className={`${
			className ?? ''
		} text-base inline-flex border border-black p-4 py-2 tracking-wider hover:text-white hover:bg-black`}
	>
		{children}
	</div>
)

export default Button
